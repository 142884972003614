<template>
  <div>
    <div v-if="isLoading">Loading club members...</div>
    <div v-else>
      <h1>Club Members</h1>

      <div class="header-container">

        <input type="text" v-model="search" placeholder="Search members" />

        <select v-model="selectedTeam">
          <option value="">All</option>
          <option v-for="team in teams" :key="team._id" :value="team._id">{{ team.name }}</option>
        </select>

      </div>

      <table class="table">
        <thead>
          <tr>
            <th @click="sort('displayName')">Display Name</th>
            <th @click="sort('pronoun')">Pronoun</th>
            <th @click="sort('birthDate')">Birth Date</th>
            <th @click="sort('email')">Email</th>
            <th @click="sort('phoneNumber')">Phone Number</th>
            <th @click="sort('cipp')">CIPP</th>
            <th @click="sort('status')">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="member in sortedMembers" :key="member._id" v-on:click="navigateToProfile(member._id)">
            <td>{{ displayName(member) }}</td>
            <td>{{ member.pronoun }}</td>
            <td>{{ this.$formatDate(member.birthDate, "MM/dd/yyyy") }}</td>
            <td>{{ member.email }}</td>
            <td>{{ member.phoneNumber }}</td>
            <td>{{ member.cipp }}</td>
            <td>{{ member.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { apiGet } from '@/api';
import { displayName } from '@/common';

export default {
  data() {
    return {
      isLoading: true,
      members: [],
      teams: [],
      selectedTeam: "",
      search: '',
      currentSort: '',
      currentSortDir: 'asc',
    };
  },
  computed: {
    sortedMembers() {
      const searchTerms = this.search.toLowerCase().split(' ');

      let filteredMembers = this.members.filter(member => {
        // Ensure member.teams is defined and handle filtering by team correctly
        const isInSelectedTeam = this.selectedTeam.length === 0 || (member.team && member.team._id === this.selectedTeam);

        return searchTerms.every(searchTerm => {
          return (
            member.email?.toLowerCase().includes(searchTerm) ||
            member.firstName?.toLowerCase().includes(searchTerm) ||
            member.lastName?.toLowerCase().includes(searchTerm) ||
            member.alias?.toLowerCase().includes(searchTerm) ||
            member.nickname?.toLowerCase().includes(searchTerm) ||
            member.cipp?.toLowerCase().includes(searchTerm) ||
            member.status?.toLowerCase().includes(searchTerm)
          );
        }) && isInSelectedTeam;
      });

      // Sort the filtered members
      let sortedMembers = [...filteredMembers];
      let modifier = this.currentSortDir === 'desc' ? -1 : 1;

      if (this.currentSort === 'displayName') {
        return sortedMembers.sort((a, b) => {
          if (this.displayName(a) < this.displayName(b)) return -1 * modifier;
          if (this.displayName(a) > this.displayName(b)) return 1 * modifier;
          return 0;
        });
      } else {
        return sortedMembers.sort((a, b) => {
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        });
      }
    },
  },
  methods: {
    displayName,
    async fetchMembers() {
      const membersResponse = await apiGet('members');
      this.members = membersResponse;
    },
    async fetchTeams() {
      const teamsResponse = await apiGet('teams');
      this.teams = teamsResponse.teams;
    },
    navigateToProfile(memberId) {
      this.$router.push(`/member-profile/${memberId}`);
    },
    sort(field) {
      if (this.currentSort === field) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      } else {
        this.currentSort = field;
        this.currentSortDir = 'asc';
      }
    },
  },
  async created() {
    await this.fetchMembers();
    await this.fetchTeams();
    this.isLoading = false;

  },
};
</script>

<style scoped>
.table {
  /* width: 100%; */
  border-collapse: collapse;
  white-space: nowrap;
}

thead {
  position: sticky;
  top: 0;
  /* background: white; */
  z-index: 1;
}

.table th,
.table td {
  border: 1px solid #ddd;
}

.table tr:nth-child(even) {
  /* background-color: #f2f2f2; */
}

.table tr:hover {
  /* background-color: #ddd; */
  cursor: pointer;
}

.horizontal-select {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.checkbox-container input[type=checkbox] {
  margin-right: 5px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>