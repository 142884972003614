<template>
  <div>
    <h1>Officers</h1>

    <!-- Grouped by team -->
    <div v-for="(teamOfficers, teamName) in groupedOfficers" :key="teamName" class="team-section">
      <h2>{{ teamName }}</h2>
      <div class="officer-grid">
        <div v-for="officer in teamOfficers" :key="officer._id" class="officer-card">
          
          <!-- Centered Position, Photo, and Name -->
          <div class="officer-details">
            <div class="officer-position">{{ officer.position }}</div>
            <!-- Wrap photo and name together in a flexbox -->
            <div class="officer-photos-names">
              <div v-for="member in officer.memberDetails" :key="member._id" class="officer-member">
                <img :src="member.imageUrl" alt="Member Picture" class="member-picture" />
                <div class="officer-name">
                  {{ displayName(member, { lastNameFirst: false, showNickname: false, showAlias: true }) }}
                  <div v-if="member.nickname" class="officer-nickname">"{{ member.nickname }}"</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import { apiGet } from '@/api';
import defaultProfileImageUrl from '@/../images/default-profile-pic.jpg';
import { displayName } from '@/common'; // Import the displayName function

export default {
  data() {
    return {
      officers: [],
      teams: [],
      groupedOfficers: {}, 
    };
  },
  methods: {
    async fetchOfficers() {
      const response = await apiGet('officers');
      const officers = response.officers;

      for (const officer of officers) {
        officer.memberDetails = await this.fetchMemberDetails(officer.members);
      }

      this.officers = officers; 
      this.groupOfficersByTeam();
    },

    async fetchMemberDetails(memberIds) {
      const memberDetails = [];
      for (const memberId of memberIds) {
        try {
          const memberResponse = await apiGet(`members/${memberId}`);
          const member = memberResponse;

          const imageUrl = await this.getProfileImageUrl(member);
          memberDetails.push({ ...member, imageUrl });
        } catch (error) {
          console.error(`Error fetching member ${memberId}: ${error.message}`);
          memberDetails.push({ _id: memberId, firstName: 'Member not found', lastName: '', imageUrl: defaultProfileImageUrl });
        }
      }
      return memberDetails;
    },

    async getProfileImageUrl(member) {
      try {
        if (member && member.profileImageUrl) {
          const existsResponse = await apiGet(`members/getProfilePic/${member._id}`);
          if (existsResponse.exists) {
            const response = await apiGet(`members/getSignedUrl/${member._id}`);
            const imageResponse = await fetch(response.signedUrl);
            if (imageResponse.ok) {
              return response.signedUrl; 
            }
          }
        }
      } catch (error) {
        console.error("Error fetching signed URL: ", error);
      }
      return defaultProfileImageUrl;
    },

    async fetchTeams() {
      const teamsResponse = await apiGet('teams');
      this.teams = teamsResponse.teams;
    },

    getTeamName(teamId) {
      const team = this.teams.find(team => team._id === teamId);
      return team ? team.name : 'Club'; 
    },

    groupOfficersByTeam() {
      const grouped = {};

      for (const officer of this.officers) {
        const teamName = officer.team ? this.getTeamName(officer.team) : 'Club';

        if (!grouped[teamName]) {
          grouped[teamName] = [];
        }

        grouped[teamName].push(officer);
      }

      this.groupedOfficers = grouped;
    },

    // Use the imported displayName function
    displayName
  },

  async created() {
    await this.fetchTeams(); 
    await this.fetchOfficers(); 
  }
};
</script>

<style scoped>
.team-section {
  margin-bottom: 2em;
}

.officer-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 1.5em; 
}

.officer-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.officer-details {
  margin-bottom: 1em;
}

.officer-position {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.officer-photos-names {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em; 
}

.officer-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.member-picture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 0.5em;
}

.officer-name {
  font-size: 1em;
}

.officer-nickname {
  font-size: 0.9em;
  color: gray;
}
</style>
